<template>
    <div class="edit">
        <p class="edit-intro">
            Hey {{ user.email }}, you can update you password below or <a href="#" @click.prevent="logOut" class="edit-logout">log out here</a>.
        </p>
        <transition name="fade">
            <h4 v-if="success">You have successfully updated your password!</h4>
        </transition>
        <form v-if="!success" @submit.prevent="submit" class="edit-form">
            <form-input type="password" label="Old Password" name="oldPassword" :value.sync="oldPassword" minlength="6" title="Please enter a minimum of 6 characters." />
            <form-input type="password" label="New Password" name="newPassword" :value.sync="newPassword" minlength="6" title="Please enter a minimum of 6 characters." />
            <form-errors v-if="errors" :errors="errors" />
            <form-button :copy="button" :disabled="processing" />
        </form>
    </div>
</template>

<script>
    import { mapMutations } from 'vuex'
    import FormInput from 'components/common/forms/Input'
    import FormErrors from 'components/common/forms/Errors'
    import FormButton from 'components/common/forms/Button'
    import { PASSWORD } from 'config'

    export default {
        components: {
            FormInput,
            FormErrors,
            FormButton
        },
        props: {
            user: Object
        },
        data: () => ({
            oldPassword: '',
            newPassword: '',
            processing: false,
            errors: false,
            success: false
        }),
        computed: {
            button() {
                return this.processing ? 'Processing...' : 'Update password'
            }
        },
        methods: {
            logOut() {
                this.resetApp()

                this.$emit('close')
            },
            submit() {
                this.processing = true

                axios
                    .post(PASSWORD, {
                        email: this.user.email,
                        oldPassword: this.oldPassword,
                        newPassword: this.newPassword
                    })
                    .then(({ data }) => {
                        this.errors = false
                        this.success = true
                    })
                    .catch(({ response }) => {
                        this.errors = response.data.error || response.data.errors
                    })
                    .finally(() => {
                        this.processing = false
                    })
            },
            ...mapMutations(['resetApp'])
        }
    }
</script>

<style lang="scss" scoped>
    .edit {
        &-intro {
            margin: 0;
            padding-bottom: 2rem;
        }
        &-logout {
            text-decoration: underline;
        }
    }
</style>
