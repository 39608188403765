<template>
    <div class="account">
        <edit-account v-if="user" :user="user" @close="$emit('close')" />
        <start-account v-else @close="$emit('close')" />
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import StartAccount from './Start'
    import EditAccount from './Edit'

    export default {
        components: {
            EditAccount,
            StartAccount
        },
        computed: {
            ...mapState(['user'])
        }
    }
</script>
