/**
 * Set Auth token for axios
 *
 * @param {object} user
 */
export function setAuthToken(token) {
    if (! token) return

    window.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

/**
 * Get the current user time
 *
 * @return {string} time
 */
export function now() {
    return new Date().getTime()
}

/**
 * Convert MS to pretty human readable time
 *
 * @param {int} ms
 * @return {string} time
 */
export function humanTime(ms) {
    let hours = parseInt((ms / (1000 * 60 * 60)))
    let seconds = parseInt((ms / 1000) % 60)
    let minutes = parseInt((ms / (1000 * 60)) % 60)

    hours = (hours < 10) ? '0' + hours : hours
    minutes = (minutes < 10) ? '0' + minutes : minutes
    seconds = (seconds < 10) ? '0' + seconds : seconds

    return `${hours}:${minutes}:${seconds}`
}

/**
 * Check if a response has errors
 *
 * @param {object} response
 * @return {bool} error
 */
export function error(response) {
    return response.status !== 200 || !response.data.success
}

/**
 * Remove http and trailing slashes
 *
 * @param {string} url
 * @return {string} url
 */
export function prettifyUrl(url) {
    return url.split('://')[1].replace(/\/$/, '')
}

/**
 * Toggle an item in an array
 *
 * @param {string} value
 * @param {array} target
 * @return {array} values
 */
export function toggle(value, target) {
    if (target.includes(value)) {
        return target.filter((item) => {
            return item !== value
        })
    }

    return target.concat([value])
}

/**
 * Debounce an event
 *
 * @param {func} callback
 * @param {number} time
 */
export function debounce(fn, delay) {
    let debounceID = null

    return function() {
        clearTimeout(debounceID)
        let args = arguments
        debounceID = setTimeout(() => {
            fn.apply(this, args)
        }, delay)
    }
}
