<template>
    <li class="link-item" :class="{ editing: editing }">
        <list-handler />
        <a :href="link.url" class="link-item-link" target="_blank" rel="noopener">
            <list-icon name="solid-link" :size="0.7" /><span v-if="!editing" class="link-item-name">{{ link.name }}</span>
        </a>
        <input v-if="editing" type="text" name="linkname" :value="link.name" @input="updateName" @keyup.enter="update" ref="linkInput" class="link-item-edit">
        <list-action v-if="!editing" icon="solid-pencil-alt" :size="0.7" @click="edit" />
        <list-action v-if="!editing" icon="solid-times" :size="0.8" :id="link.id" @click="remove" />
    </li>
</template>

<script>
    import Vue from 'vue'
    import { mapMutations } from 'vuex'
    import ListHandler from 'components/common/lists/Handler'
    import ListIcon from 'components/common/lists/Icon'
    import ListAction from 'components/common/lists/Action'
    import { prettifyUrl } from 'utilities/helpers'

    export default {
        components: {
            ListHandler,
            ListIcon,
            ListAction
        },
        props: {
            link: Object
        },
        data: () => ({
            name: '',
            editing: false
        }),
        methods: {
            edit() {
                this.editing = true
                this.name = this.link.name

                this.$nextTick(() => this.$refs.linkInput.focus())
            },
            updateName({ target }) {
                this.name = target.value
            },
            update() {
                const name = this.name ? this.name : prettifyUrl(this.link.url)

                this.updateContent({ key: 'links', content: {
                    ...this.link, name
                }})

                this.name = ''
                this.editing = false

                this.blurInput()
            },
            remove() {
                this.removeContent({ key: 'links', id: this.link.id })
            },
            blurInput() {
                this.$refs.linkInput.blur()
            },
            ...mapMutations(['updateContent', 'removeContent'])
        }
    }
</script>

<style lang="scss" scoped>
    .link-item {
        display: flex;
        align-items: stretch;
        padding-right: 0.5rem;
        &-link,
        &-edit {
            flex-grow: 1;
            display: flex;
            align-items: center;
            &:hover {
                color: var(--secondary);
            }
        }
        &-link {
            transition-property: color;
        }
        &-name {
            padding-right: 1rem;
        }
        &.editing .link-item-link {
            flex-grow: 0;
        }
    }
</style>
