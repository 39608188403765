<template>
    <div class="form-errors">
        <p v-for="(error, index) in errorsArray" :key="index" class="form-error">
            {{ error }}
        </p>
    </div>
</template>

<script>
    export default {
        props: ['errors'],
        computed: {
            errorsArray() {
                if (typeof this.errors === 'object') {
                    return [].concat(...Object.values(this.errors)).map(error => error)
                }

                return  [this.errors]
            }
        }
    }
</script>

<style lang="scss" scoped>
    .form-error {
        font-size: 0.9rem;
        color: var(--alert);
    }
</style>
