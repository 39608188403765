<template>
    <section class="admin">
        <button type="button" @click="toggleModal" class="admin-toggle">
            <icon name="solid-cog" :spin="syncing" :size="1" />
        </button>
        <modal v-show="modal" :show="modal" @close="toggleModal">
            <ul class="admin-menu">
                <li class="admin-menu-item" :class="{ active: active('settings')}">
                    <a href="#" @click.prevent="updateSection('settings')" class="admin-menu-link">Settings</a>
                </li>
                <li class="admin-menu-item" :class="{ active: active('account')}">
                    <a href="#" @click.prevent="updateSection('account')" class="admin-menu-link">Account</a>
                </li>
                <li class="admin-menu-item" :class="{ active: active('data')}">
                    <a href="#" @click.prevent="updateSection('data')" class="admin-menu-link">Data</a>
                </li>
            </ul>
            <admin-settings v-if="active('settings')" @close="toggleModal" />
            <admin-account v-if="active('account')" @close="toggleModal" />
            <admin-data v-if="active('data')" @close="toggleModal" />
        </modal>
    </section>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import Modal from 'components/common/Modal'
    import AdminSettings from './Settings'
    import AdminAccount from './account/'
    import AdminData from './Data'
    import { debounce } from 'utilities/helpers'

    export default {
        components: {
            Modal,
            AdminSettings,
            AdminAccount,
            AdminData
        },
        data: () => ({
            modal: false,
            section: 'settings'
        }),
        computed: {
            ...mapState(['settings', 'user', 'syncing'])
        },
        watch: {
            settings() {
                this.sync()
            }
        },
        methods: {
            toggleModal() {
                this.modal = ! this.modal
            },
            active(section) {
                return this.section === section
            },
            updateSection(section) {
                this.section = section
            },
            sync: debounce(function() {
                this.saveContent('settings')
            }, 1000),
            ...mapActions(['saveContent'])
        }
    }
</script>

<style lang="scss" scoped>
    .admin {
        &-toggle {
            top: 1rem;
            right: 1rem;
            position: absolute;
        }
    }
    .admin-menu {
        display: flex;
        text-align: center;
        justify-content: space-between;
        margin: -1.25rem 0 2.75rem;
        border-bottom: 1px dashed;
        &-item.active .admin-menu-link {
            color: var(--info);
        }
        &-link {
            display: inline-block;
            padding: 0.25rem 1rem;
        }
    }
</style>
