<template>
    <form @submit.prevent="submit" ref="launcherForm" class="launcher-form">
        <form-input name="name" label="Name" :value.sync="name" />
        <form-input name="url" label="URL" type="url" :value.sync="url" />
        <form-select name="icon" label="Icon" :selected="icon" :options="icons" @select="updateIcon" />
        <form-button :copy="button" type="submit" />
        <button type="button" v-if="this.edit.id" @click="remove" aria-label="Remove item" class="launcher-form-remove">
            <icon name="solid-trash" :size="1.05" />
        </button>
    </form>
</template>

<script>
    import { mapMutations } from 'vuex'
    import uuid from 'uuid/v4'
    import FormInput from 'components/common/forms/Input'
    import FormSelect from 'components/common/forms/Select'
    import FormButton from 'components/common/forms/Button'
    import icons from 'utilities/icons'

    export default {
        components: {
            FormSelect,
            FormInput,
            FormButton
        },
        props: {
            edit: Object
        },
        data: () => ({
            icons,
            id: '',
            icon: '',
            name: '',
            url: ''
        }),
        watch: {
            edit() {
                const { id = '', icon = '', name = '', url = '' } = this.edit

                this.id = id
                this.icon = icon
                this.name = name
                this.url = url

                if (! id) {
                    this.$refs.launcherForm.reset()
                }
            }
        },
        computed: {
            button() {
                return this.edit.id ? 'Update item' : 'Add new item'
            }
        },
        methods: {
            updateIcon(icon) {
                this.icon = icon
            },
            submit() {
                if (!this.name || !this.url) return

                if (this.id) {
                    this.update()
                } else {
                    this.add()
                }

                this.$emit('close')
            },
            add() {
                const { icon, name, url } = this

                this.addContent({ key: 'launcher', content: {
                    id: uuid(),
                    icon, name, url
                }})
            },
            update() {
                const { id, icon, name, url } = this

                this.updateContent({ key: 'launcher', content: { id, icon, name, url }})
            },
            remove() {
                this.removeContent({ key: 'launcher', id: this.edit.id })

                this.$emit('close')
            },
            ...mapMutations(['addContent', 'updateContent', 'removeContent'])
        }
    }
</script>

<style lang="scss" scoped>
    .launcher-form {
        text-align: left;
        &-remove {
            float: right;
            display: flex;
            color: var(--alert);
            padding: var(--pad);
            border: 1px solid transparent;
            border-radius: var(--radius);
            &:hover {
                opacity: 0.5;
            }
            &:focus {
                border-color: currentColor;
            }
        }
    }
</style>
