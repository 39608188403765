<template>
    <widget :widget="widget" raised>
        <div slot="title">Links</div>
        <draggable v-model="links" tag="ul" draggable=".link-item" handle=".list-handler" class="links">
            <link-item v-for="link in links" :link="link" :key="link.id" />
            <list-input name="links" :value.sync="value" :placeholder="placeholder" @enter="process" :left="1.2" />
            <no-content v-if="! links.length">Add a new link above</no-content>
        </draggable>
    </widget>
</template>

<script>
    import { mapState, mapMutations, mapActions } from 'vuex'
    import draggable from 'vuedraggable'
    import uuid from 'uuid/v4'
    import Widget from '../Widget'
    import ListInput from 'components/common/lists/Input'
    import NoContent from 'components/content/NoContent'
    import LinkItem from './Item'
    import { prettifyUrl, debounce } from 'utilities/helpers'

    export default {
        components: {
            draggable,
            Widget,
            ListInput,
            NoContent,
            LinkItem
        },
        props: {
            widget: Object
        },
        data: () => ({
            value: '',
            name: '',
            url: ''
        }),
        computed: {
            links: {
                get() {
                    return this.$store.state.links.items
                },
                set(sorted) {
                    this.$store.commit('sortContent', { key: 'links', content: sorted })
                }
            },
            placeholder() {
                if (!this.url & !this.name) {
                    return null
                }

                return this.url ? 'Type link name' : 'Paste link URL'
            },
            ...mapState(['user'])
        },
        watch: {
            links() {
                this.sync()
            }
        },
        methods: {
            isInvalid() {
                return (!this.value && !this.url || this.name && !this.isUrl)
            },
            setData() {
                if (!this.url && this.isUrl) {
                    this.url = this.value
                } else {
                    this.name = this.value ? this.value : prettifyUrl(this.url)
                }

                this.value = ''
            },
            process() {
                this.isUrl = /^(?:http)(?:s)?(?:\:\/\/)(?:.*)(?:\.)\w+/.test(this.value)

                if (this.isInvalid()) return

                this.setData()

                if (this.url && this.name) {
                    this.add()
                }
            },
            add() {
                const { name, url } = this

                this.addContent({ key: 'links', content: {
                    id: uuid(), name, url
                }})

                this.name = ''
                this.url = ''
            },
            sync: debounce(function() {
                this.saveContent('links')
            }, 1000),
            ...mapMutations(['addContent']),
            ...mapActions(['saveContent'])
        }
    }
</script>

<style lang="scss" scoped>
    .links {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
        .list-input, .no-content {
            grid-column: 1 / -1;
        }
    }
</style>
