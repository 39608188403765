<template>
    <li class="todo-item" :class="{ completed: todo.completed }">
        <list-handler />
        <div class="todo-item-toggle">
            <input type="checkbox" :id="todo.id" :checked="todo.completed" @change="updateCompleted" class="todo-item-checkbox">
            <label :for="todo.id" class="todo-item-label">
                <list-icon :name="todo.completed ? 'regular-check-square': 'regular-square'" />
            </label>
        </div>
        <input type="text" name="todoname" :value="todo.name" @input="updateName" @keyup.enter="blurInput" ref="todoInput" class="todo-item-name">
        <list-action icon="solid-times" :id="todo.id" :size="0.8" @click="remove" />
    </li>
</template>

<script>
    import { mapMutations } from 'vuex'
    import ListHandler from 'components/common/lists/Handler'
    import ListIcon from 'components/common/lists/Icon'
    import ListAction from 'components/common/lists/Action'

    export default {
        components: {
            ListHandler,
            ListIcon,
            ListAction
        },
        props: {
            todo: Object
        },
        methods: {
            updateName({ target }) {
                this.updateContent({ key: 'todos', content: {
                    ...this.todo, name: target.value
                }})
            },
            updateCompleted() {
                this.updateContent({ key: 'todos', content: {
                    ...this.todo, completed: !this.todo.completed
                }})
            },
            remove() {
                this.removeContent({ key: 'todos', id: this.todo.id })
            },
            blurInput() {
                this.$refs.todoInput.blur()
            },
            ...mapMutations(['updateContent', 'removeContent'])
        }
    }
</script>

<style lang="scss" scoped>
    .todo-item {
        display: flex;
        align-items: center;
        &-label {
            display: flex;
            cursor: pointer;
        }
        &-checkbox {
            opacity: 0;
            left: -999rem;
            position: absolute;
        }
        &-name {
            flex-grow: 1;
        }
        &.completed .todo-item-name {
            text-decoration: line-through;
        }
    }
</style>
