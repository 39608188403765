<template>
    <div class="clock">
        <time :datetime="time" class="clock-time">
            {{ hours }}<b class="clock-colon">:</b>{{ minutes }}<span class="clock-meridiem">{{ meridiem }}</span><icon v-if="runningTimers" name="regular-clock" :size="1" class="clock-timer" />
        </time>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        data: () => ({
            time: null,
            hours: null,
            minutes: null,
            meridiem: null
        }),
        mounted() {
            this.updateTime()

            setInterval(() => this.updateTime(), 1000 * 60)
        },
        computed: {
            ...mapGetters(['runningTimers'])
        },
        methods: {
            getTime() {
                const now = new Date()
                const [hours, minutes] =  now.toLocaleTimeString('en-AU').split(':')
                const [, meridiem] =  now.toLocaleTimeString('en-AU').split(' ')

                return {
                    minutes,
                    time: now.toISOString(),
                    hours: hours > 12 ? hours - 12 : hours,
                    meridiem: meridiem ? meridiem : hours >= 12 ? 'pm': 'am'
                }
            },
            updateTime() {
                const { time, hours, minutes, meridiem  } = this.getTime()

                this.time = time
                this.hours = hours
                this.minutes = minutes
                this.meridiem = meridiem
            }
        }
    }
</script>

<style lang="scss" scoped>
    .clock {
        text-align: center;
        margin-bottom: 1.5rem;
        &-time {
            font-size: 3rem;
            font-weight: bold;
            position: relative;
            @media (min-width: 500px) {
                font-size: 6rem;
            }
        }
        &-colon {
            display: inline-block;
            transform: translateY(-0.5rem);
        }
        &-meridiem {
            font-size: 0.75em;
        }
        &-timer {
            position: absolute;
            right: -0.5em;
            bottom: 0.3em;
            @media (min-width: 500px) {
                right: -0.3em;
            }
        }
    }
</style>
