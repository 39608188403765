<template>
    <div class="start">
        <transition name="fade">
            <h4 v-if="resetSent">We've sent you a email with a link to reset your password.</h4>
        </transition>
        <form v-if="forgot && ! resetSent" @submit.prevent="sendReset" class="start-reset">
            <form-input type="email" label="Email Address" name="email" :value.sync="email" />
            <form-errors v-if="resetErrors" :errors="resetErrors" />
            <form-button :copy="resetButton" :disabled="resetting" />
            <a href="#" @click.prevent="toggleForgot" class="start-form-toggle">Log in / Sign up</a>
        </form>
        <form v-if="!forgot" @submit.prevent="processUser" class="start-form">
            <p>Log in or sign up below. If you've forgotten your password <a href="#" @click.prevent="toggleForgot" class="start-reset-toggle">click here</a>.</p>
            <form-input type="email" label="Email Address" name="email" :value.sync="email" />
            <form-input type="password" label="Password" name="password" :value.sync="password" minlength="6" title="Please enter a minimum of 6 characters." />
            <form-input v-if="! isLogin()" label="Invite Access Code" name="code" :value.sync="code" />
            <form-errors v-if="formErrors" :errors="formErrors" />
            <form-button :copy="formButton" :disabled="processing" />
            <a href="#" @click.prevent="toggleForm" class="start-form-toggle">{{ toggleText }}</a>
        </form>
    </div>
</template>

<script>
    import { mapMutations, mapActions } from 'vuex'
    import FormInput from 'components/common/forms/Input'
    import FormErrors from 'components/common/forms/Errors'
    import FormButton from 'components/common/forms/Button'
    import { setAuthToken } from 'utilities/helpers'
    import { LOGIN, SIGNUP, FORGOT } from 'config'

    export default {
        components: {
            FormInput,
            FormErrors,
            FormButton
        },
        data: () => ({
            forgot: '',
            email: '',
            password: '',
            code: '',
            processing: false,
            formErrors: false,
            resetting: false,
            resetErrors: false,
            resetSent: false,
            form: 'login',
            defaultError: 'Sorry, something went wrong.'
        }),
        computed: {
            formButton() {
                if (this.processing) {
                    return 'Processing...'
                }

                return this.isLogin() ? 'Log in' : 'Sign up'
            },
            resetButton() {
                return this.resetting ? 'Proccessing...' : 'Reset password'
            },
            toggleText() {
                return this.isLogin() ? 'Sign up! (Invite only)' : 'Log in here!'
            }
        },
        methods: {
            isLogin() {
                return this.form === 'login'
            },
            toggleForm() {
                this.errors = null
                this.form = this.isLogin() ? 'signup' : 'login'
            },
            toggleForgot() {
                this.forgot = ! this.forgot
            },
            sendReset() {
                this.resetting = true

                axios
                    .post(FORGOT, {
                        email: this.email
                    })
                    .then(({ data }) => {
                        this.resetErrors = false
                        this.resetSent = true
                    })
                    .catch(({ response }) => {
                        this.resetErrors = response.data.error || response.data.errors || this.defaultError
                    })
                    .finally(() => {
                        this.resetting = false
                    })
            },
            processUser() {
                this.processing = true
                const endpoint = this.isLogin() ? LOGIN : SIGNUP

                axios
                    .post(endpoint, {
                        email: this.email,
                        password: this.password,
                        code: this.code
                    })
                    .then(({ data }) => {
                        this.formErrors = false

                        this.updateUser({
                            email: data.email,
                            token: data.api_token
                        })

                        setAuthToken(data.api_token)

                        if (this.isLogin()) {
                            this.handleLogin(data)
                        } else {
                            this.syncAllContent()
                        }

                        this.$emit('close')
                    })
                    .catch(({ response }) => {
                        this.formErrors = response.data.error || response.data.errors || this.defaultError
                    })
                    .finally(() => {
                        this.processing = false
                    })
            },
            handleLogin({ settings, widgets }) {
                this.delaySync()
                this.updateWidgets(widgets)
                settings && this.updateSettings(settings)
            },
            ...mapMutations(['updateUser', 'updateSettings', 'updateWidgets']),
            ...mapActions(['delaySync', 'syncAllContent'])
        }
    }
</script>

<style lang="scss" scoped>
    .start {
        &-reset-toggle {
            text-decoration: underline;
        }
        &-form-toggle {
            display: block;
            padding: 1rem 0 0 0.5rem;
            @media (min-width: 500px) {
                float: right;
                padding: 0.9rem 0;
            }
        }
    }
</style>
