<template>
    <widget :widget="widget" raised>
        <div slot="title">Timers <span v-if="timers.length">{{ total }}</span></div>
        <draggable v-model="timers" tag="ul" draggable=".timer-item" handle=".list-handler" class="timers">
            <timer-item v-for="timer in timers" :timer="timer" :key="timer.id" />
            <list-input name="timers" :value.sync="value" @enter="add" :left="1.4" />
            <no-content v-if="! timers.length">Add a new timer above</no-content>
        </draggable>
    </widget>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
    import draggable from 'vuedraggable'
    import uuid from 'uuid/v4'
    import Tinycon from 'tinycon'
    import Widget from '../Widget'
    import ListInput from 'components/common/lists/Input'
    import NoContent from 'components/content/NoContent'
    import TimerItem from './Item'
    import { now, debounce, humanTime } from 'utilities/helpers'

    export default {
        components: {
            draggable,
            Widget,
            ListInput,
            NoContent,
            TimerItem
        },
        props: {
            widget: Object
        },
        data: () => ({
            value: '',
            total: 0
        }),
        mounted() {
            Tinycon.setOptions({
                background: '#e02c4a'
            })

            this.setTotal()
        },
        computed: {
            ...mapGetters(['runningTimers']),
            timers: {
                get() {
                    return this.$store.state.timers.items
                },
                set(sorted) {
                    this.$store.commit('sortContent', { key: 'timers', content: sorted })
                }
            },
            ...mapState(['user'])
        },
        watch: {
            timers() {
                this.sync()

                if (this.runningTimers && ! this.interval) {
                    this.interval = setInterval(() => this.setTotal(), 1000)
                }
                if (this.interval && ! this.runningTimers) {
                    clearInterval(this.interval)
                    this.interval = null
                }
                if (! this.interval) {
                    this.setTotal()
                }
            },
            runningTimers() {
                Tinycon.setBubble(this.runningTimers)
            }
        },
        methods: {
            setTotal() {
                const total = this.timers.reduce((total, timer) => {
                    const runningTime = timer.running ? now() - timer.running : 0
                    return total + (timer.total + runningTime)
                }, 0)

                this.total = humanTime(total)
            },
            add() {
                if (!this.value) return

                this.addContent({ key: 'timers', content: {
                    id: uuid(),
                    name: this.value,
                    running: now(),
                    total: 0
                }})

                this.value = ''
            },
            sync: debounce(function() {
                this.saveContent('timers')
            }, 1000),
            ...mapMutations(['addContent']),
            ...mapActions(['saveContent'])
        }
    }
</script>
