<template>
    <icon :name="name" :size="size" class="list-icon" />
</template>

<script>
    export default {
        props: {
            name: String,
            size: {
                type: Number,
                default: 0.9
            }
        }
    }
</script>

<style lang="scss" scoped>
    .list-icon {
        width: 1.5rem;
        margin-right: 0.5rem;
        transform: translateY(2px);
    }
</style>
