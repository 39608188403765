<template>
    <widget :widget="widget" center>
        <draggable v-model="launcher" tag="ul" draggable=".launcher-item-draggable" class="launcher">
            <launcher-item v-for="item in launcher" :key="item.id" :launcher="item" @edit="edit" draggable />
            <launcher-item :launcher="{ id: '', icon: 'solid-plus-circle', name: 'Add new', url: '#' }" @open="showForm" />
        </draggable>
        <modal v-show="form" :show="form" @close="hideForm">
            <launcher-form @close="hideForm" :edit="launch" />
        </modal>
    </widget>
</template>

<script>
    import draggable from 'vuedraggable'
    import { mapState, mapActions } from 'vuex'
    import Widget from '../Widget'
    import Modal from 'components/common/Modal'
    import LauncherItem from './Item'
    import LauncherForm from './Form'
    import { debounce } from 'utilities/helpers'

    export default {
        components: {
            draggable,
            Widget,
            Modal,
            LauncherForm,
            LauncherItem
        },
        props: {
            widget: Object
        },
        data: () => ({
            launch: {},
            form: false
        }),
        computed: {
            launcher: {
                get() {
                    return this.$store.state.launcher.items
                },
                set(sorted) {
                    this.$store.commit('sortContent', { key: 'launcher', content: sorted })
                }
            },
            ...mapState(['user'])
        },
        watch: {
            launcher() {
                this.sync()
            }
        },
        methods: {
            edit(item) {
                this.launch = item
                this.form = true
            },
            showForm(item) {
                this.launch = {}
                this.form = true
            },
            hideForm() {
                this.form = false
            },
            sync: debounce(function() {
                this.saveContent('launcher')
            }, 1000),
            ...mapActions(['saveContent'])
        }
    }
</script>

<style lang="scss">
    .launcher {
        display: grid;
        grid-gap: calc(var(--gap) * 0.75);
        grid-template-columns: repeat(auto-fit, 88px);
        justify-content: center;
    }
</style>
