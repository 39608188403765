<template>
    <div class="reset">
        <transition name="fade">
            <h4 v-if="success">You have successfully updated your password!</h4>
        </transition>
        <form v-if="!success" @submit.prevent="submit" class="reset-form">
            <h3>Password reset</h3>
            <input type="hidden" name="token" :value="token" />
            <form-input type="email" label="Email address" name="email" :value.sync="email" />
            <form-input type="password" label="Password" name="password" :value.sync="password" minlength="6" title="Please enter a minimum of 6 characters." />
            <form-input type="password" label="Password Confirmation" name="password_confirmation" :value.sync="password_confirmation" minlength="6" title="Please enter a minimum of 6 characters." />
            <form-errors v-if="errors" :errors="errors" />
            <form-button :copy="button" :disabled="processing" />
        </form>
    </div>
</template>

<script>
    import FormInput from 'components/common/forms/Input'
    import FormErrors from 'components/common/forms/Errors'
    import FormButton from 'components/common/forms/Button'
    import { RESET } from 'config'

    export default {
        components: {
            FormInput,
            FormErrors,
            FormButton
        },
        props: {
            token: String
        },
        data: () => ({
            email: '',
            password: '',
            password_confirmation: '',
            processing: false,
            errors: false,
            success: false
        }),
        computed: {
            button() {
                return this.processing ? 'Processing...' : 'Reset password'
            }
        },
        methods: {
            submit() {
                const { token, email, password, password_confirmation } = this

                this.processing = true

                axios
                    .post(RESET, {
                        token, email, password, password_confirmation
                    })
                    .then(({ data }) => {
                        this.errors = false
                        this.success = true
                    })
                    .catch(({ response }) => {
                        this.errors = response.data.error || response.data.errors
                    })
                    .finally(() => {
                        this.processing = false
                    })
            }
        }
    }
</script>

<style>

</style>
