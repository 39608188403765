import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import settings from 'store/settings'
import { launcher, todos, notes, links, timers } from 'store/widgets'
import { now, error } from 'utilities/helpers'
import { patchedItems, unsyncedItems } from 'utilities/store'
import { API, SYNC } from 'config'

Vue.use(Vuex)

const initialState = {
    user: null,
    settings,
    welcomed: false,
    online: true,
    syncing: false,
    syncError: false,
    syncPaused: false,
    launcher,
    todos,
    links,
    timers,
    notes
}

export default new Vuex.Store({
    state: { ...initialState },
    getters: {
        activeTheme(state) {
            return state.settings.themes.find(item => item.active).name
        },
        activeWidgets(state) {
            return state.settings.widgets.filter(item => item.visible).map(item => item.name)
        },
        runningTimers(state) {
            return state.timers.items.filter(item => item.running).length
        }
    },
    mutations: {
        resetApp(state) {
            for (let key in initialState) {
                state[key] = initialState[key]
            }
        },
        clearData(state) {
            state.launcher.items = []
            state.todos.items = []
            state.notes.items = []
            state.links.items = []
            state.timers.items = []
        },
        welcomeReceived(state) {
            state.welcomed = true
        },
        updateUser(state, user) {
            state.user = user
        },
        updateConnectivity(state, online) {
            state.online = online
        },
        updateSettings(state, settings) {
            state.settings = { updated: now(), ...settings }
        },
        updateWidget(state, widget) {
            const widgets = state.settings.widgets.map(item => (widget.name == item.name) ? widget : item)

            state.settings = { updated: now(), ...state.settings, widgets }
        },
        updateWidgets(state, widgets) {
            for (let key in widgets) {
                if (widgets[key]) state[key] = widgets[key]
            }
        },
        patchWidgets(state, widgets) {
            for (let key in widgets) {
                const patched = patchedItems(widgets[key], state[key])
                const unsynced = unsyncedItems(state[key], patched)

                state[key] = {  synced: now(), updated: now(), items: [ ...patched, ...unsynced ] }
            }
        },
        sortWidgets(state, sorted) {
            state.settings = { updated: now(), ...state.settings, widgets: sorted }
        },
        addContent(state, { key, content }) {
            state[key].items.push({ ...content, updated: now() })
        },
        updateContent(state, { key, content }) {
            if (key === 'notes') {
                state[key] = { ...state[key], updated: now(), items: content }
            } else {
                state[key] = {
                    ...state[key],
                    updated: now(),
                    items: state[key].items.map(item => {
                        return content.id == item.id ? { ...content, updated: now() } : item
                    })
                }
            }
        },
        removeContent(state, { key, id }) {
            state[key].items = state[key].items.filter(item => item.id !== id)
        },
        sortContent(state, { key, content }) {
            state[key] = { ...state[key], updated: now(), items: content }
        },
        setSyncedTime(state, key) {
            state[key].synced = now()
        },
        syncing(state) {
            state.syncing = true
            state.syncError = false
        },
        syncSuccess(state) {
            state.syncing = false
            state.syncError = false
        },
        syncFailure(state, error) {
            state.syncing = false
            state.syncError = error
        },
        syncStart(state) {
            state.syncPaused = false
        },
        syncStop(state) {
            state.syncPaused = true
        }
    },
    actions: {
        saveContent({ state, commit }, key) {
            if (!state.user || state.syncPaused) return

            const message = `Sorry, something wen't wrong while syncing the ${key}.`
            const offline = `${key} will synchronise when your back online.`

            commit('syncing')

            axios.post(API + key, {
                [key]: state[key]
            })
            .then(response => {
                if (!error(response)) {
                    commit('syncSuccess')
                    commit('setSyncedTime', key)
                } else {
                    commit('syncFailure', state.online ? message : offline)
                }
            })
            .catch(errors => {
                commit('syncFailure', state.online ? message : offline)
            })
        },
        fetchContent({ commit, dispatch, state }, { delay = false }) {
            if (!state.user || state.syncPaused) return

            const message = `Sorry, something wen't wrong while syncing your data.`

            commit('syncing')

            let synced = {
                settings: state.settings.synced
            }
            state.settings.widgets.forEach(({ name }) => {
                synced[name] = state[name].synced
            })

            axios.post(SYNC, synced).then(response => {
                const { data: { settings, widgets } } = response

                if (!error(response)) {
                    commit('syncSuccess')
                    delay && dispatch('delaySync')
                    commit('patchWidgets', widgets)
                    settings && commit('updateSettings', settings)
                } else {
                    commit('syncFailure', message)
                }
            })
            .catch(errors => {
                commit('syncFailure', message)
            })
        },
        syncAllContent({ dispatch, state }) {
            dispatch('saveContent', 'settings')
            state.settings.widgets.forEach(widget => dispatch('saveContent', widget.name))
        },
        syncUpdatedContent({ dispatch, state }) {
            state.settings.widgets.forEach(({ name, updated, synced}) => {
                if (state[name].updated > state[name].synced) {
                    dispatch('saveContent', name)
                }
            })
        },
        delaySync({ commit }) {
            commit('syncStop')
            setTimeout(() => {
                commit('syncStart')
            }, 1250)
        }
    },
    plugins: [createPersistedState({ key: 'app' })]
})
