export default [
    'brands-500px',
    'brands-accessible-icon',
    'brands-accusoft',
    'brands-adn',
    'brands-adversal',
    'brands-affiliatetheme',
    'brands-algolia',
    'brands-amazon',
    'brands-amazon-pay',
    'brands-amilia',
    'brands-android',
    'brands-angellist',
    'brands-angrycreative',
    'brands-angular',
    'brands-app-store',
    'brands-app-store-ios',
    'brands-apper',
    'brands-apple',
    'brands-apple-pay',
    'brands-asymmetrik',
    'brands-audible',
    'brands-autoprefixer',
    'brands-avianex',
    'brands-aviato',
    'brands-aws',
    'brands-bandcamp',
    'brands-behance',
    'brands-behance-square',
    'brands-bimobject',
    'brands-bitbucket',
    'brands-bitcoin',
    'brands-bity',
    'brands-black-tie',
    'brands-blackberry',
    'brands-blogger',
    'brands-blogger-b',
    'brands-bluetooth',
    'brands-bluetooth-b',
    'brands-btc',
    'brands-buromobelexperte',
    'brands-buysellads',
    'brands-cc-amazon-pay',
    'brands-cc-amex',
    'brands-cc-apple-pay',
    'brands-cc-diners-club',
    'brands-cc-discover',
    'brands-cc-jcb',
    'brands-cc-mastercard',
    'brands-cc-paypal',
    'brands-cc-stripe',
    'brands-cc-visa',
    'brands-centercode',
    'brands-chrome',
    'brands-cloudscale',
    'brands-cloudsmith',
    'brands-cloudversify',
    'brands-codepen',
    'brands-codiepie',
    'brands-connectdevelop',
    'brands-contao',
    'brands-cpanel',
    'brands-creative-commons',
    'brands-creative-commons-by',
    'brands-creative-commons-nc',
    'brands-creative-commons-nc-eu',
    'brands-creative-commons-nc-jp',
    'brands-creative-commons-nd',
    'brands-creative-commons-pd',
    'brands-creative-commons-pd-alt',
    'brands-creative-commons-remix',
    'brands-creative-commons-sa',
    'brands-creative-commons-sampling',
    'brands-creative-commons-sampling-plus',
    'brands-creative-commons-share',
    'brands-css3',
    'brands-css3-alt',
    'brands-cuttlefish',
    'brands-d-and-d',
    'brands-dashcube',
    'brands-delicious',
    'brands-deploydog',
    'brands-deskpro',
    'brands-deviantart',
    'brands-digg',
    'brands-digital-ocean',
    'brands-discord',
    'brands-discourse',
    'brands-dochub',
    'brands-docker',
    'brands-draft2digital',
    'brands-dribbble',
    'brands-dribbble-square',
    'brands-dropbox',
    'brands-drupal',
    'brands-dyalog',
    'brands-earlybirds',
    'brands-ebay',
    'brands-edge',
    'brands-elementor',
    'brands-ember',
    'brands-empire',
    'brands-envira',
    'brands-erlang',
    'brands-ethereum',
    'brands-etsy',
    'brands-expeditedssl',
    'brands-facebook',
    'brands-facebook-f',
    'brands-facebook-messenger',
    'brands-facebook-square',
    'brands-firefox',
    'brands-first-order',
    'brands-first-order-alt',
    'brands-firstdraft',
    'brands-flickr',
    'brands-flipboard',
    'brands-fly',
    'brands-font-awesome',
    'brands-font-awesome-alt',
    'brands-font-awesome-flag',
    'brands-font-awesome-logo-full',
    'brands-fonticons',
    'brands-fonticons-fi',
    'brands-fort-awesome',
    'brands-fort-awesome-alt',
    'brands-forumbee',
    'brands-foursquare',
    'brands-free-code-camp',
    'brands-freebsd',
    'brands-fulcrum',
    'brands-galactic-republic',
    'brands-galactic-senate',
    'brands-get-pocket',
    'brands-gg',
    'brands-gg-circle',
    'brands-git',
    'brands-git-square',
    'brands-github',
    'brands-github-alt',
    'brands-github-square',
    'brands-gitkraken',
    'brands-gitlab',
    'brands-gitter',
    'brands-glide',
    'brands-glide-g',
    'brands-gofore',
    'brands-goodreads',
    'brands-goodreads-g',
    'brands-google',
    'brands-google-drive',
    'brands-google-play',
    'brands-google-plus',
    'brands-google-plus-g',
    'brands-google-plus-square',
    'brands-google-wallet',
    'brands-gratipay',
    'brands-grav',
    'brands-gripfire',
    'brands-grunt',
    'brands-gulp',
    'brands-hacker-news',
    'brands-hacker-news-square',
    'brands-hips',
    'brands-hire-a-helper',
    'brands-hooli',
    'brands-hotjar',
    'brands-houzz',
    'brands-html5',
    'brands-hubspot',
    'brands-imdb',
    'brands-instagram',
    'brands-internet-explorer',
    'brands-ioxhost',
    'brands-itunes',
    'brands-itunes-note',
    'brands-java',
    'brands-jedi-order',
    'brands-jenkins',
    'brands-joget',
    'brands-joomla',
    'brands-js',
    'brands-js-square',
    'brands-jsfiddle',
    'brands-keybase',
    'brands-keycdn',
    'brands-kickstarter',
    'brands-kickstarter-k',
    'brands-korvue',
    'brands-laravel',
    'brands-lastfm',
    'brands-lastfm-square',
    'brands-leanpub',
    'brands-less',
    'brands-line',
    'brands-linkedin',
    'brands-linkedin-in',
    'brands-linode',
    'brands-linux',
    'brands-lyft',
    'brands-magento',
    'brands-mandalorian',
    'brands-mastodon',
    'brands-maxcdn',
    'brands-medapps',
    'brands-medium',
    'brands-medium-m',
    'brands-medrt',
    'brands-meetup',
    'brands-microsoft',
    'brands-mix',
    'brands-mixcloud',
    'brands-mizuni',
    'brands-modx',
    'brands-monero',
    'brands-napster',
    'brands-nintendo-switch',
    'brands-node',
    'brands-node-js',
    'brands-npm',
    'brands-ns8',
    'brands-nutritionix',
    'brands-odnoklassniki',
    'brands-odnoklassniki-square',
    'brands-old-republic',
    'brands-opencart',
    'brands-openid',
    'brands-opera',
    'brands-optin-monster',
    'brands-osi',
    'brands-page4',
    'brands-pagelines',
    'brands-palfed',
    'brands-patreon',
    'brands-paypal',
    'brands-periscope',
    'brands-phabricator',
    'brands-phoenix-framework',
    'brands-phoenix-squadron',
    'brands-php',
    'brands-pied-piper',
    'brands-pied-piper-alt',
    'brands-pied-piper-hat',
    'brands-pied-piper-pp',
    'brands-pinterest',
    'brands-pinterest-p',
    'brands-pinterest-square',
    'brands-playstation',
    'brands-product-hunt',
    'brands-pushed',
    'brands-python',
    'brands-qq',
    'brands-quinscape',
    'brands-quora',
    'brands-r-project',
    'brands-ravelry',
    'brands-react',
    'brands-readme',
    'brands-rebel',
    'brands-red-river',
    'brands-reddit',
    'brands-reddit-alien',
    'brands-reddit-square',
    'brands-rendact',
    'brands-renren',
    'brands-replyd',
    'brands-researchgate',
    'brands-resolving',
    'brands-rocketchat',
    'brands-rockrms',
    'brands-safari',
    'brands-sass',
    'brands-schlix',
    'brands-scribd',
    'brands-searchengin',
    'brands-sellcast',
    'brands-sellsy',
    'brands-servicestack',
    'brands-shirtsinbulk',
    'brands-simplybuilt',
    'brands-sistrix',
    'brands-sith',
    'brands-skyatlas',
    'brands-skype',
    'brands-slack',
    'brands-slack-hash',
    'brands-slideshare',
    'brands-snapchat',
    'brands-snapchat-ghost',
    'brands-snapchat-square',
    'brands-soundcloud',
    'brands-speakap',
    'brands-spotify',
    'brands-stack-exchange',
    'brands-stack-overflow',
    'brands-staylinked',
    'brands-steam',
    'brands-steam-square',
    'brands-steam-symbol',
    'brands-sticker-mule',
    'brands-strava',
    'brands-stripe',
    'brands-stripe-s',
    'brands-studiovinari',
    'brands-stumbleupon',
    'brands-stumbleupon-circle',
    'brands-superpowers',
    'brands-supple',
    'brands-teamspeak',
    'brands-telegram',
    'brands-telegram-plane',
    'brands-tencent-weibo',
    'brands-themeisle',
    'brands-trade-federation',
    'brands-trello',
    'brands-tripadvisor',
    'brands-tumblr',
    'brands-tumblr-square',
    'brands-twitch',
    'brands-twitter',
    'brands-twitter-square',
    'brands-typo3',
    'brands-uber',
    'brands-uikit',
    'brands-uniregistry',
    'brands-untappd',
    'brands-usb',
    'brands-ussunnah',
    'brands-vaadin',
    'brands-viacoin',
    'brands-viadeo',
    'brands-viadeo-square',
    'brands-viber',
    'brands-vimeo',
    'brands-vimeo-square',
    'brands-vimeo-v',
    'brands-vine',
    'brands-vk',
    'brands-vnv',
    'brands-vuejs',
    'brands-weibo',
    'brands-weixin',
    'brands-whatsapp',
    'brands-whatsapp-square',
    'brands-whmcs',
    'brands-wikipedia-w',
    'brands-windows',
    'brands-wolf-pack-battalion',
    'brands-wordpress',
    'brands-wordpress-simple',
    'brands-wpbeginner',
    'brands-wpexplorer',
    'brands-wpforms',
    'brands-xbox',
    'brands-xing',
    'brands-xing-square',
    'brands-y-combinator',
    'brands-yahoo',
    'brands-yandex',
    'brands-yandex-international',
    'brands-yelp',
    'brands-yoast',
    'brands-youtube',
    'brands-youtube-square',
    'solid-address-book',
    'solid-address-card',
    'solid-adjust',
    'solid-align-center',
    'solid-align-justify',
    'solid-align-left',
    'solid-align-right',
    'solid-allergies',
    'solid-ambulance',
    'solid-american-sign-language-interpreting',
    'solid-anchor',
    'solid-angle-double-down',
    'solid-angle-double-left',
    'solid-angle-double-right',
    'solid-angle-double-up',
    'solid-angle-down',
    'solid-angle-left',
    'solid-angle-right',
    'solid-angle-up',
    'solid-archive',
    'solid-arrow-alt-circle-down',
    'solid-arrow-alt-circle-left',
    'solid-arrow-alt-circle-right',
    'solid-arrow-alt-circle-up',
    'solid-arrow-circle-down',
    'solid-arrow-circle-left',
    'solid-arrow-circle-right',
    'solid-arrow-circle-up',
    'solid-arrow-down',
    'solid-arrow-left',
    'solid-arrow-right',
    'solid-arrow-up',
    'solid-arrows-alt',
    'solid-arrows-alt-h',
    'solid-arrows-alt-v',
    'solid-assistive-listening-systems',
    'solid-asterisk',
    'solid-at',
    'solid-audio-description',
    'solid-backward',
    'solid-balance-scale',
    'solid-ban',
    'solid-band-aid',
    'solid-barcode',
    'solid-bars',
    'solid-baseball-ball',
    'solid-basketball-ball',
    'solid-bath',
    'solid-battery-empty',
    'solid-battery-full',
    'solid-battery-half',
    'solid-battery-quarter',
    'solid-battery-three-quarters',
    'solid-bed',
    'solid-beer',
    'solid-bell',
    'solid-bell-slash',
    'solid-bicycle',
    'solid-binoculars',
    'solid-birthday-cake',
    'solid-blender',
    'solid-blind',
    'solid-bold',
    'solid-bolt',
    'solid-bomb',
    'solid-book',
    'solid-book-open',
    'solid-bookmark',
    'solid-bowling-ball',
    'solid-box',
    'solid-box-open',
    'solid-boxes',
    'solid-braille',
    'solid-briefcase',
    'solid-briefcase-medical',
    'solid-broadcast-tower',
    'solid-broom',
    'solid-bug',
    'solid-building',
    'solid-bullhorn',
    'solid-bullseye',
    'solid-burn',
    'solid-bus',
    'solid-calculator',
    'solid-calendar',
    'solid-calendar-alt',
    'solid-calendar-check',
    'solid-calendar-minus',
    'solid-calendar-plus',
    'solid-calendar-times',
    'solid-camera',
    'solid-camera-retro',
    'solid-capsules',
    'solid-car',
    'solid-caret-down',
    'solid-caret-left',
    'solid-caret-right',
    'solid-caret-square-down',
    'solid-caret-square-left',
    'solid-caret-square-right',
    'solid-caret-square-up',
    'solid-caret-up',
    'solid-cart-arrow-down',
    'solid-cart-plus',
    'solid-certificate',
    'solid-chalkboard',
    'solid-chalkboard-teacher',
    'solid-chart-area',
    'solid-chart-bar',
    'solid-chart-line',
    'solid-chart-pie',
    'solid-check',
    'solid-check-circle',
    'solid-check-square',
    'solid-chess',
    'solid-chess-bishop',
    'solid-chess-board',
    'solid-chess-king',
    'solid-chess-knight',
    'solid-chess-pawn',
    'solid-chess-queen',
    'solid-chess-rook',
    'solid-chevron-circle-down',
    'solid-chevron-circle-left',
    'solid-chevron-circle-right',
    'solid-chevron-circle-up',
    'solid-chevron-down',
    'solid-chevron-left',
    'solid-chevron-right',
    'solid-chevron-up',
    'solid-child',
    'solid-church',
    'solid-circle',
    'solid-circle-notch',
    'solid-clipboard',
    'solid-clipboard-check',
    'solid-clipboard-list',
    'solid-clock',
    'solid-clone',
    'solid-closed-captioning',
    'solid-cloud',
    'solid-cloud-download-alt',
    'solid-cloud-upload-alt',
    'solid-code',
    'solid-code-branch',
    'solid-coffee',
    'solid-cog',
    'solid-cogs',
    'solid-coins',
    'solid-columns',
    'solid-comment',
    'solid-comment-alt',
    'solid-comment-dots',
    'solid-comment-slash',
    'solid-comments',
    'solid-compact-disc',
    'solid-compass',
    'solid-compress',
    'solid-copy',
    'solid-copyright',
    'solid-couch',
    'solid-credit-card',
    'solid-crop',
    'solid-crosshairs',
    'solid-crow',
    'solid-crown',
    'solid-cube',
    'solid-cubes',
    'solid-cut',
    'solid-database',
    'solid-deaf',
    'solid-desktop',
    'solid-diagnoses',
    'solid-dice',
    'solid-dice-five',
    'solid-dice-four',
    'solid-dice-one',
    'solid-dice-six',
    'solid-dice-three',
    'solid-dice-two',
    'solid-divide',
    'solid-dna',
    'solid-dollar-sign',
    'solid-dolly',
    'solid-dolly-flatbed',
    'solid-donate',
    'solid-door-closed',
    'solid-door-open',
    'solid-dot-circle',
    'solid-dove',
    'solid-download',
    'solid-dumbbell',
    'solid-edit',
    'solid-eject',
    'solid-ellipsis-h',
    'solid-ellipsis-v',
    'solid-envelope',
    'solid-envelope-open',
    'solid-envelope-square',
    'solid-equals',
    'solid-eraser',
    'solid-euro-sign',
    'solid-exchange-alt',
    'solid-exclamation',
    'solid-exclamation-circle',
    'solid-exclamation-triangle',
    'solid-expand',
    'solid-expand-arrows-alt',
    'solid-external-link-alt',
    'solid-external-link-square-alt',
    'solid-eye',
    'solid-eye-dropper',
    'solid-eye-slash',
    'solid-fast-backward',
    'solid-fast-forward',
    'solid-fax',
    'solid-feather',
    'solid-female',
    'solid-fighter-jet',
    'solid-file',
    'solid-file-alt',
    'solid-file-archive',
    'solid-file-audio',
    'solid-file-code',
    'solid-file-excel',
    'solid-file-image',
    'solid-file-medical',
    'solid-file-medical-alt',
    'solid-file-pdf',
    'solid-file-powerpoint',
    'solid-file-video',
    'solid-file-word',
    'solid-film',
    'solid-filter',
    'solid-fire',
    'solid-fire-extinguisher',
    'solid-first-aid',
    'solid-flag',
    'solid-flag-checkered',
    'solid-flask',
    'solid-folder',
    'solid-folder-open',
    'solid-font',
    'solid-font-awesome-logo-full',
    'solid-football-ball',
    'solid-forward',
    'solid-frog',
    'solid-frown',
    'solid-futbol',
    'solid-gamepad',
    'solid-gas-pump',
    'solid-gavel',
    'solid-gem',
    'solid-genderless',
    'solid-gift',
    'solid-glass-martini',
    'solid-glasses',
    'solid-globe',
    'solid-golf-ball',
    'solid-graduation-cap',
    'solid-greater-than',
    'solid-greater-than-equal',
    'solid-h-square',
    'solid-hand-holding',
    'solid-hand-holding-heart',
    'solid-hand-holding-usd',
    'solid-hand-lizard',
    'solid-hand-paper',
    'solid-hand-peace',
    'solid-hand-point-down',
    'solid-hand-point-left',
    'solid-hand-point-right',
    'solid-hand-point-up',
    'solid-hand-pointer',
    'solid-hand-rock',
    'solid-hand-scissors',
    'solid-hand-spock',
    'solid-hands',
    'solid-hands-helping',
    'solid-handshake',
    'solid-hashtag',
    'solid-hdd',
    'solid-heading',
    'solid-headphones',
    'solid-heart',
    'solid-heartbeat',
    'solid-helicopter',
    'solid-history',
    'solid-hockey-puck',
    'solid-home',
    'solid-hospital',
    'solid-hospital-alt',
    'solid-hospital-symbol',
    'solid-hourglass',
    'solid-hourglass-end',
    'solid-hourglass-half',
    'solid-hourglass-start',
    'solid-i-cursor',
    'solid-id-badge',
    'solid-id-card',
    'solid-id-card-alt',
    'solid-image',
    'solid-images',
    'solid-inbox',
    'solid-indent',
    'solid-industry',
    'solid-infinity',
    'solid-info',
    'solid-info-circle',
    'solid-italic',
    'solid-key',
    'solid-keyboard',
    'solid-kiwi-bird',
    'solid-language',
    'solid-laptop',
    'solid-leaf',
    'solid-lemon',
    'solid-less-than',
    'solid-less-than-equal',
    'solid-level-down-alt',
    'solid-level-up-alt',
    'solid-life-ring',
    'solid-lightbulb',
    'solid-link',
    'solid-lira-sign',
    'solid-list',
    'solid-list-alt',
    'solid-list-ol',
    'solid-list-ul',
    'solid-location-arrow',
    'solid-lock',
    'solid-lock-open',
    'solid-long-arrow-alt-down',
    'solid-long-arrow-alt-left',
    'solid-long-arrow-alt-right',
    'solid-long-arrow-alt-up',
    'solid-low-vision',
    'solid-magic',
    'solid-magnet',
    'solid-male',
    'solid-map',
    'solid-map-marker',
    'solid-map-marker-alt',
    'solid-map-pin',
    'solid-map-signs',
    'solid-mars',
    'solid-mars-double',
    'solid-mars-stroke',
    'solid-mars-stroke-h',
    'solid-mars-stroke-v',
    'solid-medkit',
    'solid-meh',
    'solid-memory',
    'solid-mercury',
    'solid-microchip',
    'solid-microphone',
    'solid-microphone-alt',
    'solid-microphone-alt-slash',
    'solid-microphone-slash',
    'solid-minus',
    'solid-minus-circle',
    'solid-minus-square',
    'solid-mobile',
    'solid-mobile-alt',
    'solid-money-bill',
    'solid-money-bill-alt',
    'solid-money-bill-wave',
    'solid-money-bill-wave-alt',
    'solid-money-check',
    'solid-money-check-alt',
    'solid-moon',
    'solid-motorcycle',
    'solid-mouse-pointer',
    'solid-music',
    'solid-neuter',
    'solid-newspaper',
    'solid-not-equal',
    'solid-notes-medical',
    'solid-object-group',
    'solid-object-ungroup',
    'solid-outdent',
    'solid-paint-brush',
    'solid-palette',
    'solid-pallet',
    'solid-paper-plane',
    'solid-paperclip',
    'solid-parachute-box',
    'solid-paragraph',
    'solid-parking',
    'solid-paste',
    'solid-pause',
    'solid-pause-circle',
    'solid-paw',
    'solid-pen-square',
    'solid-pencil-alt',
    'solid-people-carry',
    'solid-percent',
    'solid-percentage',
    'solid-phone',
    'solid-phone-slash',
    'solid-phone-square',
    'solid-phone-volume',
    'solid-piggy-bank',
    'solid-pills',
    'solid-plane',
    'solid-play',
    'solid-play-circle',
    'solid-plug',
    'solid-plus',
    'solid-plus-circle',
    'solid-plus-square',
    'solid-podcast',
    'solid-poo',
    'solid-portrait',
    'solid-pound-sign',
    'solid-power-off',
    'solid-prescription-bottle',
    'solid-prescription-bottle-alt',
    'solid-print',
    'solid-procedures',
    'solid-project-diagram',
    'solid-puzzle-piece',
    'solid-qrcode',
    'solid-question',
    'solid-question-circle',
    'solid-quidditch',
    'solid-quote-left',
    'solid-quote-right',
    'solid-random',
    'solid-receipt',
    'solid-recycle',
    'solid-redo',
    'solid-redo-alt',
    'solid-registered',
    'solid-reply',
    'solid-reply-all',
    'solid-retweet',
    'solid-ribbon',
    'solid-road',
    'solid-robot',
    'solid-rocket',
    'solid-rss',
    'solid-rss-square',
    'solid-ruble-sign',
    'solid-ruler',
    'solid-ruler-combined',
    'solid-ruler-horizontal',
    'solid-ruler-vertical',
    'solid-rupee-sign',
    'solid-save',
    'solid-school',
    'solid-screwdriver',
    'solid-search',
    'solid-search-minus',
    'solid-search-plus',
    'solid-seedling',
    'solid-server',
    'solid-share',
    'solid-share-alt',
    'solid-share-alt-square',
    'solid-share-square',
    'solid-shekel-sign',
    'solid-shield-alt',
    'solid-ship',
    'solid-shipping-fast',
    'solid-shoe-prints',
    'solid-shopping-bag',
    'solid-shopping-basket',
    'solid-shopping-cart',
    'solid-shower',
    'solid-sign',
    'solid-sign-in-alt',
    'solid-sign-language',
    'solid-sign-out-alt',
    'solid-signal',
    'solid-sitemap',
    'solid-skull',
    'solid-sliders-h',
    'solid-smile',
    'solid-smoking',
    'solid-smoking-ban',
    'solid-snowflake',
    'solid-sort',
    'solid-sort-alpha-down',
    'solid-sort-alpha-up',
    'solid-sort-amount-down',
    'solid-sort-amount-up',
    'solid-sort-down',
    'solid-sort-numeric-down',
    'solid-sort-numeric-up',
    'solid-sort-up',
    'solid-space-shuttle',
    'solid-spinner',
    'solid-square',
    'solid-square-full',
    'solid-star',
    'solid-star-half',
    'solid-step-backward',
    'solid-step-forward',
    'solid-stethoscope',
    'solid-sticky-note',
    'solid-stop',
    'solid-stop-circle',
    'solid-stopwatch',
    'solid-store',
    'solid-store-alt',
    'solid-stream',
    'solid-street-view',
    'solid-strikethrough',
    'solid-stroopwafel',
    'solid-subscript',
    'solid-subway',
    'solid-suitcase',
    'solid-sun',
    'solid-superscript',
    'solid-sync',
    'solid-sync-alt',
    'solid-syringe',
    'solid-table',
    'solid-table-tennis',
    'solid-tablet',
    'solid-tablet-alt',
    'solid-tablets',
    'solid-tachometer-alt',
    'solid-tag',
    'solid-tags',
    'solid-tape',
    'solid-tasks',
    'solid-taxi',
    'solid-terminal',
    'solid-text-height',
    'solid-text-width',
    'solid-th',
    'solid-th-large',
    'solid-th-list',
    'solid-thermometer',
    'solid-thermometer-empty',
    'solid-thermometer-full',
    'solid-thermometer-half',
    'solid-thermometer-quarter',
    'solid-thermometer-three-quarters',
    'solid-thumbs-down',
    'solid-thumbs-up',
    'solid-thumbtack',
    'solid-ticket-alt',
    'solid-times',
    'solid-times-circle',
    'solid-tint',
    'solid-toggle-off',
    'solid-toggle-on',
    'solid-toolbox',
    'solid-trademark',
    'solid-train',
    'solid-transgender',
    'solid-transgender-alt',
    'solid-trash',
    'solid-trash-alt',
    'solid-tree',
    'solid-trophy',
    'solid-truck',
    'solid-truck-loading',
    'solid-truck-moving',
    'solid-tshirt',
    'solid-tty',
    'solid-tv',
    'solid-umbrella',
    'solid-underline',
    'solid-undo',
    'solid-undo-alt',
    'solid-universal-access',
    'solid-university',
    'solid-unlink',
    'solid-unlock',
    'solid-unlock-alt',
    'solid-upload',
    'solid-user',
    'solid-user-alt',
    'solid-user-alt-slash',
    'solid-user-astronaut',
    'solid-user-check',
    'solid-user-circle',
    'solid-user-clock',
    'solid-user-cog',
    'solid-user-edit',
    'solid-user-friends',
    'solid-user-graduate',
    'solid-user-lock',
    'solid-user-md',
    'solid-user-minus',
    'solid-user-ninja',
    'solid-user-plus',
    'solid-user-secret',
    'solid-user-shield',
    'solid-user-slash',
    'solid-user-tag',
    'solid-user-tie',
    'solid-user-times',
    'solid-users',
    'solid-users-cog',
    'solid-utensil-spoon',
    'solid-utensils',
    'solid-venus',
    'solid-venus-double',
    'solid-venus-mars',
    'solid-vial',
    'solid-vials',
    'solid-video',
    'solid-video-slash',
    'solid-volleyball-ball',
    'solid-volume-down',
    'solid-volume-off',
    'solid-volume-up',
    'solid-walking',
    'solid-wallet',
    'solid-warehouse',
    'solid-weight',
    'solid-wheelchair',
    'solid-wifi',
    'solid-window-close',
    'solid-window-maximize',
    'solid-window-minimize',
    'solid-window-restore',
    'solid-wine-glass',
    'solid-won-sign',
    'solid-wrench',
    'solid-x-ray',
    'solid-yen-sign',
    'regular-address-book',
    'regular-address-card',
    'regular-arrow-alt-circle-down',
    'regular-arrow-alt-circle-left',
    'regular-arrow-alt-circle-right',
    'regular-arrow-alt-circle-up',
    'regular-bell',
    'regular-bell-slash',
    'regular-bookmark',
    'regular-building',
    'regular-calendar',
    'regular-calendar-alt',
    'regular-calendar-check',
    'regular-calendar-minus',
    'regular-calendar-plus',
    'regular-calendar-times',
    'regular-caret-square-down',
    'regular-caret-square-left',
    'regular-caret-square-right',
    'regular-caret-square-up',
    'regular-chart-bar',
    'regular-check-circle',
    'regular-check-square',
    'regular-circle',
    'regular-clipboard',
    'regular-clock',
    'regular-clone',
    'regular-closed-captioning',
    'regular-comment',
    'regular-comment-alt',
    'regular-comment-dots',
    'regular-comments',
    'regular-compass',
    'regular-copy',
    'regular-copyright',
    'regular-credit-card',
    'regular-dot-circle',
    'regular-edit',
    'regular-envelope',
    'regular-envelope-open',
    'regular-eye',
    'regular-eye-slash',
    'regular-file',
    'regular-file-alt',
    'regular-file-archive',
    'regular-file-audio',
    'regular-file-code',
    'regular-file-excel',
    'regular-file-image',
    'regular-file-pdf',
    'regular-file-powerpoint',
    'regular-file-video',
    'regular-file-word',
    'regular-flag',
    'regular-folder',
    'regular-folder-open',
    'regular-font-awesome-logo-full',
    'regular-frown',
    'regular-futbol',
    'regular-gem',
    'regular-hand-lizard',
    'regular-hand-paper',
    'regular-hand-peace',
    'regular-hand-point-down',
    'regular-hand-point-left',
    'regular-hand-point-right',
    'regular-hand-point-up',
    'regular-hand-pointer',
    'regular-hand-rock',
    'regular-hand-scissors',
    'regular-hand-spock',
    'regular-handshake',
    'regular-hdd',
    'regular-heart',
    'regular-hospital',
    'regular-hourglass',
    'regular-id-badge',
    'regular-id-card',
    'regular-image',
    'regular-images',
    'regular-keyboard',
    'regular-lemon',
    'regular-life-ring',
    'regular-lightbulb',
    'regular-list-alt',
    'regular-map',
    'regular-meh',
    'regular-minus-square',
    'regular-money-bill-alt',
    'regular-moon',
    'regular-newspaper',
    'regular-object-group',
    'regular-object-ungroup',
    'regular-paper-plane',
    'regular-pause-circle',
    'regular-play-circle',
    'regular-plus-square',
    'regular-question-circle',
    'regular-registered',
    'regular-save',
    'regular-share-square',
    'regular-smile',
    'regular-snowflake',
    'regular-square',
    'regular-star',
    'regular-star-half',
    'regular-sticky-note',
    'regular-stop-circle',
    'regular-sun',
    'regular-thumbs-down',
    'regular-thumbs-up',
    'regular-times-circle',
    'regular-trash-alt',
    'regular-user',
    'regular-user-circle',
    'regular-window-close',
    'regular-window-maximize',
    'regular-window-minimize',
    'regular-window-restore'
]
