<template>
    <li class="timer-item">
        <list-handler />
        <button type="button" @click="updateRunning" class="timer-item-toggle">
            <list-icon :name="timer.running ? 'solid-stop': 'solid-play'" :size="0.85" />
        </button>
        <input v-show="editTime" type="text" :value="editedTime" @input="updateTime" @blur="updateTotal" @keyup.enter="blurInput('timerTime')" ref="timerTime" class="timer-item-time" />
        <button type="button" v-show="! editTime" @click="showEdit" class="timer-item-time timer-item-edit">{{ time }}</button>
        <input type="text" name="timername" :value="timer.name" @input="updateName" @keyup.enter="blurInput('timerName')" ref="timerName" class="timer-item-name">
        <list-action icon="solid-undo" :id="timer.id" :size="0.7" @click="reset" />
        <list-action icon="solid-times" :id="timer.id" :size="0.8" @click="remove" />
    </li>
</template>

<script>
    import { mapMutations } from 'vuex'
    import ListHandler from 'components/common/lists/Handler'
    import ListIcon from 'components/common/lists/Icon'
    import ListAction from 'components/common/lists/Action'
    import { now, humanTime } from 'utilities/helpers'

    export default {
        components: {
            ListHandler,
            ListIcon,
            ListAction
        },
        props: {
            timer: Object
        },
        data: () => ({
            time: 0,
            editTime: false,
            editedTime: null
        }),
        mounted() {
            this.setTime()
        },
        beforeUpdate() {
            if (!this.interval && this.timer.running) {
                this.interval = setInterval(() => this.setTime(), 1000)
            }
            if (this.interval && !this.timer.running) {
                clearInterval(this.interval)
                this.interval = null
            }
            if (! this.interval) {
                this.setTime()
            }
        },
        beforeDestroy() {
            clearInterval(this.interval)
        },
        methods: {
            getTime() {
                const runningTime = this.timer.running ? now() - this.timer.running : 0

                return this.timer.total + runningTime
            },
            setTime() {
                this.time = humanTime(this.getTime())
            },
            updateName({ target }) {
                this.updateContent({ key: 'timers', content: {
                    ...this.timer, name: target.value
                }})
            },
            updateRunning() {
                this.updateContent({ key: 'timers', content: {
                    ...this.timer,
                    running: this.timer.running ? false : now(),
                    total: this.timer.running ? this.getTime() : this.timer.total
                }})
            },
            updateTotal() {
                const [hours = 0, mins = 0, secs = 0] = this.editedTime.split(':')
                const total = (Number(hours) * 3600000) + (Number(mins) * 60000) + (Number(secs) * 1000)

                this.hideEdit()

                this.updateContent({ key: 'timers', content: {
                    ...this.timer, total
                }})
            },
            updateTime({ target }) {
                this.editedTime = target.value
            },
            showEdit() {
                if (this.timer.running) return

                this.editTime = true
                this.editedTime = this.time

                this.$nextTick(() => this.$refs.timerTime.focus())
            },
            hideEdit() {
                this.editTime = false
                this.editedTime = null
            },
            reset() {
                this.updateContent({ key: 'timers', content: {
                    ...this.timer,
                    running: this.timer.running ? now() : false,
                    total: 0
                }})
            },
            remove() {
                this.removeContent({ key: 'timers', id: this.timer.id })
            },
            blurInput(input) {
                this.$refs[input].blur()
            },
            ...mapMutations(['updateContent', 'removeContent'])
        }
    }
</script>

<style lang="scss" scoped>
    .timer-item {
        display: flex;
        align-items: center;
        &-toggle {
            display: flex;
            transform: translateY(-1px);
        }
        &-time {
            width: 6rem;
            padding: 1px;
            font-weight: bold;
            line-height: 1.25rem;
            display: inline-block;
            text-align: left;
        }
        &-name {
            flex-grow: 1;
            border: none;
        }
    }
</style>
