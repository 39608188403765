<template>
    <transition name="modal">
        <div class="modal" @click="offClick" tabindex="-1" ref="modal" @keyup.esc="close">
            <div class="modal-container">
                <div class="modal-content">
                    <slot></slot>
                </div>
                <button type="button" @click="close" class="modal-close">
                    <icon name="solid-times" :size="1" />
                </button>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        props: {
            show: Boolean
        },
        watch: {
            show() {
                if (this.show) {
                    this.$refs.modal.focus()
                }
            }
        },
        methods: {
            offClick({ target }) {
                if (target.className === 'modal') {
                    this.close()
                }
            },
            close() {
                this.$emit('close')
            }
        }
    }
</script>

<style lang="scss" scoped>
    .modal {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 99;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--transparent);
        transition: opacity var(--fast) var(--smooth);
        &-container {
            position: relative;
            max-width: 95%;
            max-height: 95vh;
            overflow-y: scroll;
            border-radius: var(--radius);
            background-color: var(--inverted);
            box-shadow: 0 1.25rem 3.25rem var(--shadow), 0 0.75rem 1.25rem var(--shadow);
            transition: transform var(--fast) var(--smooth);
        }
        &-content {
            padding: 4rem 1.25rem 2.5rem;
             @media (min-width: 500px) {
                width: 30rem;
                padding: 4rem 3rem 3rem;
            }
        }
        &-close {
            position: absolute;
            top: 1rem;
            right: 1rem;
        }
        &-enter {
            opacity: 0;
        }
        &-leave-active {
            opacity: 0;
        }
        &-enter &-container,
        &-leave-active &-container {
            transform: translateY(0.5rem);
        }
    }
</style>
