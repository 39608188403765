<template>
    <widget :widget="widget" raised>
        <div slot="title">Notes</div>
        <textarea name="notes" :value="compiledNote" @input="update" ref="textarea" class="notes"></textarea>
        <no-content v-if="! compiledNote.length">Add a new note above</no-content>
    </widget>
</template>

<script>
    import { mapState, mapMutations, mapActions } from 'vuex'
    import autosize from 'autosize'
    import Widget from '../Widget'
    import NoContent from 'components/content/NoContent'
    import { now, debounce } from 'utilities/helpers'

    export default {
        components: {
            NoContent,
            Widget
        },
        props: {
            widget: Object
        },
        mounted() {
            autosize(this.$refs.textarea)
        },
        computed: {
            compiledNote() {
                return this.notes.items.map(note => note.content).join('\n')
            },
            ...mapState(['user', 'notes'])
        },
        watch: {
            notes() {
                this.sync()
            }
        },
        methods: {
            update({ target }) {
                const lines = target.value.split('\n')

                const notes = lines.map((note, i) => ({
                    content: note,
                    updated: this.hasChanged(note, i) ? now() : this.notes.items[i].updated
                }))

                this.updateContent({ key: 'notes', content: notes })
            },
            hasChanged(note, i) {
                return ! this.notes.items[i] || this.notes.items[i] && this.notes.items[i].content !== note
            },
            sync: debounce(function() {
                this.saveContent('notes')
            }, 1000),
            ...mapMutations(['updateContent']),
            ...mapActions(['saveContent'])
        }
    }
</script>

<style lang="scss" scoped>
    .notes {
        width: 100%;
        border: none;
        resize: none;
        padding-bottom: 2.25rem;
    }
</style>
