import { now } from 'utilities/helpers'

const updated = now()

// Default launcher state
export const launcher = {
    updated: now(),
    synced: now(),
    items: [
        {
            id: 'LAUNCHER01',
            icon: 'brands-github',
            name: 'GitHub',
            url: 'https://github.com/',
            updated
        }, {
            id: 'LAUNCHER02',
            icon: 'brands-trello',
            name: 'Trello',
            url: 'https://trello.com/',
            updated
        }, {
            id: 'LAUNCHER03',
            icon: 'brands-dropbox',
            name: 'Dropbox',
            url: 'https://dropbox.com/',
            updated
        }, {
            id: 'LAUNCHER04',
            icon: 'brands-flipboard',
            name: 'Flipboard',
            url: 'https://flipboard.com/',
            updated
        }, {
            id: 'LAUNCHER05',
            icon: 'brands-get-pocket',
            name: 'Pocket',
            url: 'https://getpocket.com/',
            updated
        }, {
            id: 'LAUNCHER06',
            icon: 'brands-twitter',
            name: 'Twitter',
            url: 'https://twitter.com/',
            updated
        }
    ]
}

// Default todos state
export const todos = {
    updated: now(),
    synced: now(),
    items: [
        {
            id: 'TODOS01',
            name: 'Here\'s my first todo',
            completed: false,
            updated
        }, {
            id: 'TODOS02',
            name: 'And my second todo',
            completed: false,
            updated
        }, {
            id: 'TODOS03',
            name: 'This is a completed todo',
            completed: true,
            updated
        }
    ]
}

// Default notes state
export const notes = {
    updated: now(),
    synced: now(),
    items: [
        {
            content: 'This is my first note.',
            updated
        }, {
            content: '',
            updated
        }, {
            content: 'Don\'t forget you can delete all sample data via the setting menu in the top right.',
            updated
        }
    ]
}

// Default links state
export const links = {
    updated: now(),
    synced: now(),
    items: [
        {
            id: 'LINKS01',
            name: 'Lonely Planet Places',
            url: 'https://www.lonelyplanet.com/places',
            updated
        }, {
            id: 'LINKS02',
            name: 'Product Hunt Newest',
            url: 'https://www.producthunt.com/newest',
            updated
        }, {
            id: 'LINKS03',
            name: 'Wait But Why',
            url: 'https://waitbutwhy.com/',
            updated
        }, {
            id: 'LINKS04',
            name: 'Here is today',
            url: 'http://www.hereistoday.com/',
            updated
        }
    ]
}

// Default timers state
export const timers = {
    updated: now(),
    synced: now(),
    items: [
        {
            id: 'TIMERS01',
            name: 'Client work',
            running: now(),
            total: 900000,
            updated
        }, {
            id: 'TIMERS02',
            name: 'Personal projects',
            running: false,
            total: 5400000,
            updated
        }
    ]
}
