// Auth routes
export const AUTH = '/auth/'
export const LOGIN = '/auth/login'
export const SIGNUP = '/auth/signup'
export const FORGOT = '/auth/forgot'
export const RESET = '/auth/reset'

// API routes
export const API = '/api/'
export const SYNC = '/api/sync'
export const SETTINGS = '/api/settings'
export const PASSWORD = '/api/password'
export const LAUNCHER = '/api/launcher'
export const TODOS = '/api/todos'
export const NOTES = '/api/notes'
export const LINKS = '/api/links'
export const TIMERS = '/api/timers'
