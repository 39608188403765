<template>
    <button :type="type" class="form-button">
        {{ copy }}
    </button>
</template>

<script>
    export default {
        props: {
            copy: String,
            type: {
                type: String,
                default: 'submit'
            }
        }
    }
</script>

<style lang="scss" scoped>
    .form-button {
        padding: var(--pad);
        border: 1px solid;
        border-radius: var(--radius);
        &:disabled {
            cursor: default;
            color: var(--tertiary);
        }
    }
</style>
