<template>
    <div class="sync">
        <transition name="fade">
            <p v-if="syncError" class="sync-error" :class="{ 'sync-info': ! online }">{{ syncError }}</p>
        </transition>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        computed: {
            ...mapState(['online', 'syncing', 'syncError']),
        }
    }
</script>

<style lang="scss" scoped>
    .sync {
        &-error {
            max-width: 69%;
            font-size: 0.75rem;
            background-color: var(--alert);
            display: inline-block;
            padding: 0.25rem 0.5rem;
            border-radius: var(--radius);
            color: var(--inverted);
            position: absolute;
            top: 0.75rem;
            right: 3rem;
            &::first-letter {
                text-transform: uppercase;
            }
        }
        &-info {
            background-color: var(--info);
        }
    }
</style>
