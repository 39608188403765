import axios from 'axios'
import { setAuthToken } from 'utilities/helpers'

// Register service worker
if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('./sw.js').then(() => {
        console.info('Service worker successfully registered.');
    }).catch((error) => {
        console.error('Service worker registration failed.', error);
    });
}

// Setup axios headers and tokens
window.axios = axios
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

// Set Auth token if we already have an app an user set
if (window.localStorage.hasOwnProperty('app')) {
    const { user } = JSON.parse(window.localStorage.getItem('app'))

    user && setAuthToken(user.token)
}

// Register the CSRF Token as a common header
const token = document.head.querySelector('meta[name="csrf-token"]')

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token')
}
