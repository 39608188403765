<template>
    <div class="data">
        <transition name="fade">
            <h4 v-if="success" class="data-message">All data has been removed!</h4>
        </transition>
        <form v-if="!success" @submit.prevent="submit">
            <p>Here you can delete all your todos, notes, links and timers at once.</p>
            <form-input label="Type 'DELETE'" name="delete" :value.sync="text" />
            <form-button copy="Delete all data" />
        </form>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations } from 'vuex'
    import FormInput from 'components/common/forms/Input'
    import FormButton from 'components/common/forms/Button'

    export default {
        components: {
            FormInput,
            FormButton
        },
        data: () => ({
            text: '',
            success: false
        }),
        methods: {
            submit() {
                if (this.text !== 'DELETE') return

                this.clearData()
                this.success = true
            },
            ...mapMutations(['clearData'])
        }
    }
</script>

<style lang="scss" scoped>
    .data-message {
        text-align: center;
    }
</style>
