import 'bootstrap'
import Vue from 'vue'
import Raven from 'raven-js'
import RavenVue from 'raven-js/plugins/vue'
import App from 'components/App'
import Icon from 'components/common/Icon'
import store from 'store'

Raven.config(process.env.MIX_SENTRY_DSN).addPlugin(RavenVue, Vue).install()

Vue.component('icon', Icon)

const app = new Vue({
    el: '#app',
    store,
    render: h => h(App)
})
