<template>
    <section class="widget" :class="{ 'widget-centered': this.center }" :style="{ '--span': widget.span }">
        <header v-if="$slots.title" class="widget-header">
            <h2 class="widget-header-title">
                <icon name="solid-bars" :size="0.5" class="widget-header-move" />
                <span class="widget-header-text"><slot name="title"></slot></span>
            </h2>
            <button v-if="raised" type="button" class="widget-header-menu" @click="toggleMenu">
                <icon name="solid-ellipsis-v" :size="0.6" class="widget-header-dots" />
            </button>
            <widget-menu v-if="menu" :widget="widget" @close="toggleMenu" />
        </header>
        <article class="widget-content" :class="{ 'widget-raised': this.raised }">
            <slot></slot>
        </article>
    </section>
</template>

<script>
    import WidgetMenu from './Menu'

    export default {
        components: {
            WidgetMenu
        },
        props: {
            widget: Object,
            center: Boolean,
            raised: Boolean
        },
        data: () => ({
            menu: false
        }),
        methods: {
            toggleMenu() {
                this.menu = ! this.menu
            }
        }
    }
</script>

<style lang="scss" scoped>
    .widget {
        display: flex;
        flex-direction: column;
        grid-column: span var(--span);
        &-centered {
            text-align: center;
        }
        &-raised {
            padding: var(--pad);
            box-shadow: 0 0.75rem 2.75rem var(--shadow), 0 0.25rem 0.75rem var(--shadow);
            &:hover, &:focus-within {
                box-shadow: 0 1.25rem 3.25rem var(--shadow), 0 0.75rem 1.25rem var(--shadow);
            }
        }
        &-content {
            position: relative;
            min-height: 8rem;
            flex-grow: 1;
            border-radius: var(--radius);
            background-color: var(--inverted);
            border: 1px solid var(--inverted);
            transition: all var(--fast) var(--smooth);

        }
        @media (max-width: 980px) {
            grid-column: span var(--columns) !important;
        }
    }
    .widget-header {
        position: relative;
        padding: 0.4rem 0.2rem;
        &-title {
            color: var(--secondary);
            cursor: move;
            margin: 0;
            position: relative;
            display: inline-block;
            font-size: 0.75rem;
            font-weight: normal;
            user-select: none;
            &:hover {
                .widget-header-text {
                    transform: translateX(1rem);
                    transition: transform var(--fast) var(--bounce);
                }
                .widget-header-move {
                    opacity: 1;
                    transition: opacity var(--medium) var(--bounce);
                }
            }
        }
        &-text {
            display: inline-block;
            transition: transform var(--medium) var(--bounce);
            span {
                padding: 1px 2px;
                font-size: 0.9em;
                background-color: var(--shadow);
                border-radius: var(--radius);
            }
        }
        &-move {
            position: absolute;
            top: 0.375em;
            left: 0.25em;
            opacity: 0;
            transition: opacity var(--fast) var(--bounce);
        }
        &-menu {
            padding: 0 0.5rem;
            position: absolute;
            top: 0.6rem;
            right: 0;
            z-index: 29;
            opacity: 0;
            &:focus {
                opacity: 1;
            }
        }
        &:hover &-menu {
            opacity: 1;
        }
    }
</style>
