<template>
    <div class="form-input input">
        <label :for="name">{{ label }}</label>
        <input :type="type" :name="name" :id="name" :value="value" @input="$emit('update:value', $event.target.value)" @keyup.enter="$emit('enter')" :title="title" v-bind="$attrs" class="form-input-value" required>
    </div>
</template>

<script>
    export default {
        props: {
            type: {
                type: String,
                default: 'text'
            },
            title: {
                type: String,
                default: ''
            },
            label: String,
            name: String,
            value: String
        },
        inheritAttrs: false
    }
</script>

<style lang="scss" scoped>
    .form-input {
        &-value {
            width: 100%;
            padding: 0.25rem 0;
            border-bottom: 1px solid;
            &:focus {
                border-bottom-color: var(--secondary);
            }
        }
    }
</style>
