<template>
    <widget :widget="widget" raised>
        <div slot="title">ToDos</div>
        <draggable v-model="todos" tag="ul" draggable=".todo-item" handle=".list-handler" class="todos">
            <todo-item v-for="todo in todos" :todo="todo" :key="todo.id" />
            <list-input name="todos" :value.sync="value" @enter="add" @paste="process" />
            <no-content v-if="! todos.length">Add a new todo above</no-content>
        </draggable>
    </widget>
</template>

<script>
    import { mapState, mapMutations, mapActions } from 'vuex'
    import draggable from 'vuedraggable'
    import uuid from 'uuid/v4'
    import Widget from '../Widget'
    import ListInput from 'components/common/lists/Input'
    import NoContent from 'components/content/NoContent'
    import TodoItem from './Item'
    import { debounce } from 'utilities/helpers'

    export default {
        components: {
            draggable,
            Widget,
            ListInput,
            NoContent,
            TodoItem
        },
        props: {
            widget: Object
        },
        data: () => ({
            value: ''
        }),
        computed: {
            todos: {
                get() {
                    return this.$store.state.todos.items
                },
                set(sorted) {
                    this.$store.commit('sortContent', { key: 'todos', content: sorted })
                }
            },
            ...mapState(['user'])
        },
        watch: {
            todos() {
                this.sync()
            }
        },
        methods: {
            add(value = null) {
                const name = value || this.value

                if (!name) return

                this.addContent({ key: 'todos', content: {
                    id: uuid(),
                    completed: false,
                    name
                }})

                this.value = ''
            },
            process(e) {
                const text = e.clipboardData.getData('Text')
                const lines = text.split('\n').filter(Boolean)

                if (lines.length > 1) {
                    e.preventDefault()
                    e.stopPropagation()

                    lines.forEach(line => this.add(line))
                }
            },
            sync: debounce(function() {
                this.saveContent('todos')
            }, 1000),
            ...mapMutations(['addContent']),
            ...mapActions(['saveContent'])
        }
    }
</script>
