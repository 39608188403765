<template>
    <svg class="icon" :class="{ spin: spin }" :style="{ width: `${size}rem`, height: `${size}rem` }">
        <use :xlink:href="href"></use>
    </svg>
</template>

<script>
    export default {
        props: {
            name: String,
            size: Number,
            spin: Boolean
        },
        computed: {
            href() {
                return `#${this.name}`
            }
        }
    }
</script>

<style lang="scss" scoped>
    .icon {
        fill: currentColor;
        display: inline-block;
        animation: spinIt 1s infinite paused;
        &.spin {
            animation-play-state: running;
        }
    }

    @keyframes spinIt {
        from {
            transform: rotate(0deg)
        }
        to {
            transform: rotate(360deg)
        }
    }
</style>
