import { render, staticRenderFns } from "./NoContent.vue?vue&type=template&id=246adb6d&scoped=true&"
var script = {}
import style0 from "./NoContent.vue?vue&type=style&index=0&id=246adb6d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "246adb6d",
  null
  
)

export default component.exports