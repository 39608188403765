<template>
    <li :class="{ 'launcher-item-draggable': draggable, 'launcher-item': true }">
        <button type="button" v-if="launcher.id" @click="$emit('edit', launcher)" class="launcher-item-edit hover">
            <icon name="solid-pencil-alt" :size="0.65" class="launcher-item-pencil" />
        </button>
        <a :href="launcher.url" class="launcher-item-link" @click="click" target="_blank" rel="noopener">
            <icon :name="launcher.icon" :label="launcher.name" :size="3.5" class="launcher-item-icon" />
            <h3 class="launcher-item-name">{{ launcher.name }}</h3>
        </a>
    </li>
</template>

<script>
    export default {
        props: {
            launcher: Object,
            draggable: Boolean
        },
        methods: {
            click(event) {
                if (! this.launcher.id) {
                    event.preventDefault()
                    this.$emit('open')
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .launcher-item {
        cursor: pointer;
        position: relative;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: var(--radius);
        transition: all var(--fast) var(--smooth);
        box-shadow: 0 0.5rem 2.5rem var(--shadow), 0 0.25rem 0.5rem var(--shadow);
        &-link {
            display: inherit;
            padding: var(--pad);
            width: 100%;

            &:focus {
                .launcher-item-name {
                    opacity: 1;
                    transform: translateY(-0.15rem);
                }
            }
        }
        &-edit {
            cursor: pointer;
            opacity: 0;
            top: 0.15rem;
            right: 0.15rem;
            width: 1rem;
            position: absolute;
        }
        &-icon {
            margin: 0 auto;
            transition: all var(--fast) var(--smooth);
        }
        &-name {
            width: 100%;
            margin: 0;
            opacity: 0;
            bottom: 0;
            left: 0;
            color: var(--primary);
            position: absolute;
            padding: 0 0.25rem;
            font-size: 0.6rem;
            font-weight: normal;
            text-align: center;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            transform: translateY(0);
            transition: all var(--fast) var(--smooth);
        }
        &:hover {
            box-shadow: 0 1rem 3rem var(--shadow), 0 0.5rem 1rem var(--shadow);
            .launcher-item-edit {
                opacity: 1;
            }
            .launcher-item-icon {
                color: var(--secondary);
            }
            .launcher-item-name {
                opacity: 1;
                transform: translateY(-0.15rem);
            }
        }
    }
</style>
