<template>
    <div class="form-group input">
        <h4 class="form-group-title">{{ label }}</h4>
        <div class="form-group-fields">
            <div v-for="option in options" :key="option.name" class="form-group-item">
                <input :type="type" :name="name" :id="option.name" :value="option.name" :checked="checked(option.name)" @input="$emit('click', option.name)" class="form-group-input">
                <label :for="option.name" class="form-group-label">{{ option.name }}</label>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            type: {
                type: String,
                default: 'radio'
            },
            name: String,
            label: String,
            options: Array,
            active: Array
        },
        methods: {
            checked(name) {
                return this.active.includes(name)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .form-group {
        text-transform: capitalize;
        &-title {
            font-size: 0.9rem;
            margin: 0 0 0.5rem;
            font-weight: normal;
        }
        &-fields {
            display: flex;
        }
        &-item {
            flex-grow: 1;
            &:first-child label {
                border-left-width: 1px;
                border-radius: var(--radius) 0 0 var(--radius);
            }
            &:last-child label {
                border-radius: 0 var(--radius) var(--radius) 0;
            }
        }
        &-input {
            opacity: 0;
            left: -999rem;
            position: absolute;
            &:checked + label {
                color: var(--inverted);
                background-color: var(--secondary);
            }
            &:checked:focus + label {
                background-color: var(--info);
            }
        }
        &-label {
            width: 100%;
            cursor: pointer;
            font-size: 0.75rem;
            padding: 0.625rem 1rem;
            border-width: 1px 1px 1px 0;
            border-style: solid;
            border-color: var(--secondary);
            @media (max-width: 800px) {
                padding: 0.5rem;
                font-size: 0.65rem;
            }
        }
    }
</style>
