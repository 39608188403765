<template>
    <div class="app" :data-theme="activeTheme" :data-online="online">
        <modal v-if="! welcomed" @close="closeWelcome">
            <welcome />
        </modal>
        <modal v-if="reset" @close="closeReset">
            <reset :token="reset" />
        </modal>
        <sync />
        <logo />
        <admin />
        <clock />
        <widgets />
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
    import Welcome from 'components/content/Welcome'
    import Reset from 'components/admin/account/Reset'
    import Modal from 'components/common/Modal'
    import Logo from 'components/content/Logo'
    import Admin from 'components/admin/'
    import Clock from 'components/content/Clock'
    import Sync from 'components/common/Sync'
    import Widgets from 'components/widgets/'
    import { RESET } from 'config'

    export default {
        components: {
            Welcome,
            Reset,
            Modal,
            Logo,
            Admin,
            Clock,
            Sync,
            Widgets
        },
        data: () => ({
            reset: null
        }),
        mounted() {
            this.setTheme()
            this.handleReset()
            this.fetchApp({ delay: true })

            window.addEventListener('online', this.setConnectivity)
            window.addEventListener('offline', this.setConnectivity)
            document.addEventListener('visibilitychange', this.fetchApp)
        },
        beforeUpdate() {
            this.setTheme()
        },
        computed: {
            ...mapState(['user', 'welcomed', 'online', 'syncError']),
            ...mapGetters(['activeTheme'])
        },
        methods: {
            setTheme() {
                document.body.classList.remove(document.body.classList[0])
                document.body.classList.add(this.activeTheme)
            },
            handleReset() {
                const path = window.location.pathname

                if (path.includes(RESET)) {
                    this.reset = path.split('/').pop()
                }
            },
            setConnectivity() {
                this.updateConnectivity(navigator.onLine)
                navigator.onLine && this.syncUpdatedContent()
            },
            fetchApp(options = {}) {
                if (! document.hidden) {
                    this.fetchContent(options)
                }
            },
            closeReset() {
                this.reset = null
                history.pushState({}, null, '/')
            },
            closeWelcome() {
                this.welcomeReceived()
            },
            ...mapMutations(['welcomeReceived', 'updateConnectivity']),
            ...mapActions(['fetchContent', 'syncUpdatedContent']),
        }
    }
</script>

<style lang="scss">
    @import '../../css/app.scss';

    .app {
        opacity: 0.75;
        &[data-online=true] {
            opacity: 1;
        }
    }
</style>
