<template>
    <li class="list-input">
        <input type="text" :name="name" :value="value" :placeholder="placeholder" @input="$emit('update:value', $event.target.value)" @keyup.enter="$emit('enter')" @paste="$emit('paste', $event)" :style="{ 'padding-left': left + 'rem' }" class="list-input-value">
    </li>
</template>

<script>
    export default {
        props: {
            name: String,
            value: String,
            placeholder: {
                default: '',
                type: String
            },
            left: {
                default: 1.5,
                type: Number
            }
        }
    }
</script>

<style lang="scss" scoped>
    .list-input {
        padding: 0.25rem 0 0;
        &-value {
            width: 100%;
        }
    }
</style>
