<template>
    <div class="menu">
        <div class="menu-backdrop" @click="offClick" />
        <div class="menu-content">
            <h5 class="menu-header">Widget width</h5>
            <ul class="menu-nav">
                <li class="menu-nav-item">
                    <a href="#" @click.prevent="update(5)">Small</a>
                </li>
                <li class="menu-nav-item">
                    <a href="#" @click.prevent="update(6)">Medium</a>
                </li>
                <li class="menu-nav-item">
                    <a href="#" @click.prevent="update(7)">Large</a>
                </li>
                <li class="menu-nav-item">
                    <a href="#" @click.prevent="update(12)">Full</a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import { mapMutations } from 'vuex'

    export default {
        props: {
            widget: Object
        },
        methods: {
            offClick({ target }) {
                if (target.className === 'menu-backdrop') {
                    this.close()
                }
            },
            update(span) {
                const { name, visible } = this.widget

                this.updateWidget({
                    name, visible, span
                })

                this.close()
            },
            close() {
                this.$emit('close')
            },
            ...mapMutations(['updateWidget'])
        }
    }
</script>

<style lang="scss" scoped>
    .menu {
        &-backdrop {
            position: fixed;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            z-index: 9;
        }
        &-header {
            margin: 0.25rem 0 0.5rem;
        }
        &-nav-item a {
            display: block;
        }
        &-content {
            padding: 2rem 1rem 0.5rem;
            width: 10rem;
            position: absolute;
            right: 0;
            top: 0;
            border-radius: var(--radius);
            background-color: var(--inverted);
            box-shadow: 0 1.25rem 3.25rem var(--shadow), 0 0.75rem 1.25rem var(--shadow);
            z-index: 19;
        }
    }
</style>
