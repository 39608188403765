<template>
    <draggable v-model="widgets" tag="main" draggable=".widget" handle=".widget-header-title" class="main">
        <template v-for="widget in widgets">
            <component v-if="widget.visible" :is="widget.name" :key="widget.name" :widget="widget" />
        </template>
    </draggable>
</template>

<script>
    import draggable from 'vuedraggable'
    import Launcher from './launcher/'
    import Todos from './todos/'
    import Notes from './notes/'
    import Links from './links/'
    import Timers from './timers/'

    export default {
        components: {
            draggable,
            Launcher,
            Todos,
            Notes,
            Links,
            Timers
        },
        computed: {
            widgets: {
                get() {
                    return this.$store.state.settings.widgets
                },
                set(sorted) {
                    this.$store.commit('sortWidgets', sorted)
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    .main {
        margin: 0 auto;
        max-width: var(--width);
        display: grid;
        grid-gap: var(--gap) 0;
        grid-template: auto / repeat(var(--columns), 1fr);
        @media (min-width: 980px) {
            grid-column-gap: var(--gap);
        }
    }
</style>
