/**
 * Get a patched list of items picking the correct versions
 * i.e. local or remote depending on which is fresher or simply adding new items
 *
 * @param {object} serverItems
 * @param {array} currentItems
 * @return {array} items
 */
export function patchedItems(server, { items }) {
    return server.items.map((serverItem, i) => {
        const currentItem = serverItem.id ? items.find(c => c.id === serverItem.id) : items[i]

        if (!currentItem) return serverItem

        return serverItem.updated > currentItem.updated ? serverItem : currentItem
    })
}

/**
 * Get all unsynced items that aren't already in the supplied list
 *
 * @param {object} currentItems
 * @param {array} patchedItems
 * @return {array} items
 */
export function unsyncedItems({ items, synced }, list) {
    return items.filter(({ id, updated }, index) => {
        const isNew = id ? !list.find(p => p.id === id) : index >= list.length
        return updated > synced && isNew
    })
}
