<template>
    <button type="button" @click="$emit('click', id)" class="list-action hover">
        <icon :name="icon" :size="size" />
    </button>
</template>

<script>
    export default {
        props: {
            icon: String,
            size: Number,
            id: {
                type: String,
                default: null
            }
        }
    }
</script>

<style lang="scss" scoped>
    .list-action {
        display: flex;
        align-items: center;
        padding: 0 0.25rem;
    }
</style>
