<template>
    <form @submit.prevent="submit" class="settings">
        <form-group label="Theme" name="themes" :options="settings.themes" :active="selectedTheme" @click="updateTheme" />
        <form-group label="Active widgets" name="widgets" type="checkbox" :options="settings.widgets" :active="selectedWidgets" @click="updateWidgets" />
        <form-button copy="Update Settings" />
    </form>
</template>

<script>
    import { mapState, mapGetters, mapMutations } from 'vuex'
    import FormGroup from 'components/common/forms/Group'
    import FormButton from 'components/common/forms/Button'
    import { toggle } from 'utilities/helpers'

    export default {
        components: {
            FormGroup,
            FormButton
        },
        data: () => ({
            selectedTheme: [],
            selectedWidgets: []
        }),
        computed: {
            ...mapState(['settings']),
            ...mapGetters(['activeTheme', 'activeWidgets'])
        },
        mounted() {
            this.selectedTheme = [this.activeTheme]
            this.selectedWidgets = this.activeWidgets
        },
        methods: {
            updateTheme(theme) {
                this.selectedTheme = [theme]
            },
            updateWidgets(widget) {
                this.selectedWidgets = toggle(widget, this.selectedWidgets)
            },
            updateActive(theme) {
                const active = this.selectedTheme.includes(theme.name) ? true : false

                return { ...theme, active }
            },
            updateVisibility(widgets) {
                const visible = this.selectedWidgets.includes(widgets.name) ? true : false

                return { ...widgets, visible }
            },
            submit() {
                this.update()

                this.$emit('close')
            },
            update() {
                this.updateSettings({
                    themes: this.settings.themes.map(this.updateActive),
                    widgets: this.settings.widgets.map(this.updateVisibility)
                })
            },
            ...mapMutations(['updateSettings'])
        }
    }
</script>
