import { now } from 'utilities/helpers'

// Default settings
export default {
    updated: now(),
    synced: now(),
    themes: [
        { name: 'light', active: true },
        { name: 'dark', active: false }
    ],
    widgets: [
        { name: 'launcher', visible: true, span: 12 },
        { name: 'todos', visible: true, span: 6 },
        { name: 'notes', visible: true, span: 6 },
        { name: 'links', visible: true, span: 7 },
        { name: 'timers', visible: true, span: 5 }
    ],
}
