<template>
    <div class="form-select input">
        <label :for="name">{{ label }}</label>
        <multiselect v-model="value" :options="options" :name="name" :id="name" placeholder="" @select="updateSelected">
            <template slot="singleLabel" slot-scope="{ option }">
                <div class="form-select-option">
                    <icon :name="option" :size="1" /><span class="form-select-text">{{ option | pretify }}</span>
                </div>
            </template>
            <template slot="option" slot-scope="props">
                <icon :name="props.option" :size="3" />
            </template>
            <template slot="noResult">
                <span>Sorry, there are no matching icons.</span>
            </template>
        </multiselect>
    </div>
</template>

<script>
    // Tabindex issue: https://github.com/shentao/vue-multiselect/issues/401
    import Multiselect from 'vue-multiselect'

    export default {
        components: {
            Multiselect
        },
        props: {
            name: String,
            label: String,
            options: Array,
            selected: String
        },
        data: () => ({
            value: ''
        }),
        watch: {
            selected() {
                this.value = this.selected
            }
        },
        filters: {
            pretify(text) {
                let parts = text.split('-')
                parts.shift()

                return parts.join(' ')
            }
        },
        methods: {
            updateSelected(name) {
                this.$emit('select', name)
            }
        }
    }
</script>


<style lang="scss">
    .form-select {
        position: relative;
        &-option {
            display: flex;
            align-items: center;
        }
        &-text {
            padding-left: 0.5rem;
            text-transform: capitalize;
        }
    }
    .multiselect {
        width: 100%;
        height: 28px;
        padding: 0.25rem 0;
        border-bottom: 1px solid;
        &__content-wrapper {
            position: absolute;
            display: block;
            width: 100%;
            overflow: auto;
            cursor: pointer;
            background-color: var(--inverted);
            box-shadow: 0 0.1rem 0.75rem var(--shadow);
            z-index: 9;
        }
        &__element {
            display: inline-block;
            width: 4.75rem;
            height: 4.75rem;
        }
        li {
            padding: 1rem;
        }
    }
</style>
